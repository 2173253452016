import React, { useContext } from "react";
import itemsStyles from "./styles/items.module.css";
// import { ReactComponent as Search } from "../../assets/search.svg";
import Burgers from "./components/Burgers";
import ShackSpecial from "./components/ShackSpecial";
import Fries from "./components/Fries";
import ShackExtras from "./components/ShackExtras";
import Drinks from "./components/Drinks";
import ShackSauces from "./components/ShackSauces";
import { GlobalContext } from "../../helpers/context";
import { addToCart, decrement } from "../../helpers/cart";

function Items() {
  const { globalState, updateGlobalState } = useContext(GlobalContext);

  const addToCartHandler = (id) => {
    addToCart(id, { globalState, updateGlobalState });
  };

  const removeFromCartHandler = (id) => {
    decrement(id, { globalState, updateGlobalState });
  };

  return (
    <div
      className={`${itemsStyles.itemsContainer}  col-xl-6 col-lg-7 col-md-8`}
    >
      {/* TODO:enable searching */}
      {/* <div className={itemsStyles.searchContainer}>
        <div className={itemsStyles.searchIconContainer}>
          <Search />
        </div>
        <input
          type="search"
          name="search"
          id="search"
          placeholder="search"
          className={itemsStyles.search}
        />
      </div> */}

      <div className={`${itemsStyles.itemsWrapper}`}>
        <Burgers
          handleAdd={addToCartHandler}
          handleRemove={removeFromCartHandler}
        />
        <ShackSpecial
          handleAdd={addToCartHandler}
          handleRemove={removeFromCartHandler}
        />
        <Fries
          handleAdd={addToCartHandler}
          handleRemove={removeFromCartHandler}
        />
        <ShackExtras
          handleAdd={addToCartHandler}
          handleRemove={removeFromCartHandler}
        />
        <Drinks
          handleAdd={addToCartHandler}
          handleRemove={removeFromCartHandler}
        />
        <ShackSauces
          handleAdd={addToCartHandler}
          handleRemove={removeFromCartHandler}
        />
      </div>
    </div>
  );
}

export default Items;
