import React from "react";
import headerStyles from "./styles/header.module.css";

import { openTime } from "../../helpers/operation";

function Header() {
  return (
    <header className={headerStyles.header}>
      <div className="col-md-3">
        <img
          src={require("../../assets/shackLogo.png")}
          alt="logo"
          className={headerStyles.logo}
        />
      </div>
      <div className={` col-md-9 ${headerStyles.topHeader}`}>
        <div>
          <h3 className={headerStyles.title}>
            The Shack Nairobi(open 10am- 9:30pm)
          </h3>

          <span
            className={headerStyles.open}
            style={{ background: openTime() === "Closed" && "red" }}
          >
            {openTime()}
          </span>
        </div>

        <p>
          0740729955 *All Burgers come with a FREE scoop of fries Except on
          Mondays(50% OFF Burgers only)
        </p>
      </div>
    </header>
  );
}

export default Header;
