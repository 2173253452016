import React, { useContext } from "react";
import itemsStyles from "../styles/items.module.css";
import { ReactComponent as Add } from "../../../assets/plus.svg";
import { ReactComponent as Subtract } from "../../../assets/dash-lg.svg";

import data from "../../../data";
import { GlobalContext } from "../../../helpers/context";

function Drinks({ handleAdd, handleRemove }) {
  const drinks = data.filter((meal) => meal.category === "drinks");

  const {
    globalState: { cartItems },
  } = useContext(GlobalContext);
  return (
    <section id="drinks" className={itemsStyles.section}>
      <div className={itemsStyles.title}>
        <span>Drinks</span>
      </div>

      <div className={itemsStyles.cardContainer}>
        {drinks.map((meal) => (
          <div className={itemsStyles.card} key={meal.id}>
            <img
              src={meal.image_url || require("../../../assets/shackLogo.png")}
              alt="food-imgd"
              className={itemsStyles.foodImg}
            />
            <div className={itemsStyles.cardInfo}>
              <div className={itemsStyles.cardInfoTop}>
                <div>
                  <h5>{meal.meal}</h5>
                  {cartItems[meal.id] && (
                    <span className={itemsStyles.itemCount}>
                      {cartItems[meal.id].qty}
                    </span>
                  )}
                </div>
                <p>{meal.description && meal.description}</p>
              </div>

              <div className={itemsStyles.priceContainer}>
                <Subtract
                  className={itemsStyles.subtract}
                  onClick={(e) => handleRemove(meal.id)}
                />
                <span>ksh {meal.price}</span>
                <Add onClick={(e) => handleAdd(meal.id)} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Drinks;
