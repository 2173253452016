import React from "react";
import navStyles from "./styles/nav.module.css";

function Nav() {
  return (
    <nav className="col-xl-3 col-lg-2 top-0 sticky-top">
      <ul className={navStyles.nav}>
        <li className={navStyles.linkContainer}>
          <a className={navStyles.link} href="#burgers">
            Burgers
          </a>

          <div className={navStyles.borderDiv}></div>
        </li>

        <li className={navStyles.linkContainer}>
          <a className={navStyles.link} href="#shackSpecials">
            Shack Specials
          </a>

          <div className={navStyles.borderDiv}></div>
        </li>

        <li className={navStyles.linkContainer}>
          <a className={navStyles.link} href="#fries">
            More Fries?
          </a>

          <div className={navStyles.borderDiv}></div>
        </li>

        <li className={navStyles.linkContainer}>
          <a className={navStyles.link} href="#shackExtras">
            Shack Extras
          </a>

          <div className={navStyles.borderDiv}></div>
        </li>

        <li className={navStyles.linkContainer}>
          <a className={navStyles.link} href="#drinks">
            Drinks
          </a>

          <div className={navStyles.borderDiv}></div>
        </li>

        <li className={navStyles.linkContainer}>
          <a className={navStyles.link} href="#shackSauces">
            Shack Sauces
          </a>

          <div className={navStyles.borderDiv}></div>
        </li>
      </ul>
    </nav>
  );
}

export default Nav;
