const data = [
  {
    id: 1,
    category: "burger",
    meal: "Shack Tower",
    description:
      "3 Grilled Beef patties (100g each) topped with Cheese & BBQ onion and a scoop of fries",
    price: 700,
    image_url:
      "https://onwhatsapp.s3.amazonaws.com/production/product_image/19718523_shack_tower.png",
  },
  {
    id: 2,
    category: "burger",
    meal: "Double Shack",
    description:
      "2 Grilled Beef patties (100g each) topped with Cheese, BBQ onion and a scoop of fries",
    price: 600,
    image_url:
      "https://onwhatsapp.s3.amazonaws.com/production/product_image/49051863_double_shack.png",
  },
  {
    id: 3,
    category: "burger",
    meal: "Crispy Cuckoo",
    description: "Crispy Fried chicken 100g patty and a scoop of fries",
    price: 600,
    image_url: "",
  },
  {
    id: 5,
    category: "burger",
    meal: "Shack Burger",
    description:
      "1 Grilled Beef patty (100g) topped with Cheese & BBQ onion and a scoop of fries",
    price: 500,
    image_url: "",
  },

  {
    id: 6,
    category: "burger",
    meal: "Cuckoo Shack",
    description: "Grilled chicken 100g patty and a scoop of fries",
    price: 600,
    image_url: "",
  },

  {
    id: 7,
    category: "burger",
    meal: "Zinga Burger",
    description:
      "Cheesy beef 200g patty, macon & white sauce and a scoop of fries",
    price: 700,
    image_url: "",
  },

  {
    id: 8,
    category: "burger",
    meal: "Shack power house",
    description:
      "3 Grilled Beef, Chicken, and Steak patties (100g each) topped with Cheese, BBQ onion and a scoop of fries",
    price: 750,
    image_url: "",
  },

  {
    id: 9,
    category: "burger",
    meal: "Steak Shack",
    description:
      "1 Grilled Rump Steak patty (100g) topped with Cheese, BBQ onion and a scoop of fries",
    price: 600,
    image_url: "",
  },

  {
    id: 30,
    category: "shackSpecials",
    meal: "Rump Steak",
    description:
      "200g grilled Rump Steak served with a side of Veggies & Fries",
    price: 550,
    image_url: "",
  },

  {
    id: 10,
    category: "shackSpecials",
    meal: "Wings (8 pcs)",
    description: "8 pcs of Chicken Wings. Specify if Hot & Sour, Spicy, or BBQ",
    price: 400,
    image_url: "",
  },

  {
    id: 11,
    category: "shackSpecials",
    meal: "Wings (4 pcs)",
    description: "4 pcs of Chicken Wings. Specify if Hot & Sour, Spicy, or BBQ",
    price: 250,
    image_url: "",
  },

  {
    id: 12,
    category: "shackSpecials",
    meal: "Chips Cuckoo",
    description: "100g of chicken breast served with fries",
    price: 300,
    image_url: "",
  },

  {
    id: 13,
    category: "fries",
    meal: "Chicken Loaded fries",
    description:
      "Classic Shack Fries, loaded with Cheddar Cheese & Chicken strips.",
    price: 400,
    image_url: "",
  },

  {
    id: 14,
    category: "fries",
    meal: "Seasoned fries",
    description: "Seasoned fries with skin",
    price: 150,
    image_url: "",
  },

  {
    id: 15,
    category: "fries",
    meal: "Cheese Steak Fries",
    price: 450,
    image_url: "",
  },

  {
    id: 16,
    category: "fries",
    meal: "Masala Fries",
    price: 200,
    image_url: "",
  },

  {
    id: 17,
    category: "shackExtras",
    meal: "Chicken Patty",
    description: "100g of juicy grilled chicken patty.",
    price: 200,
    image_url: "",
  },

  { id: 18, category: "shackExtras", meal: "Cheese", price: 50, image_url: "" },

  {
    id: 19,
    category: "shackExtras",
    meal: "BBQ Onion Sauce",
    price: 50,
    image_url: "",
  },

  {
    id: 20,
    category: "shackExtras",
    meal: "Veggies",
    price: 50,
    image_url: "",
  },

  {
    id: 21,
    category: "drinks",
    meal: "Oreo Milkshake Big",
    description: "Oreo, Mocha 500 ml",
    price: 350,
    image_url: "",
  },

  {
    id: 22,
    category: "drinks",
    meal: "Oreo Milkshake Small",
    description: "Oreo, Mocha 300 ml",
    price: 250,
    image_url: "",
  },

  {
    id: 23,
    category: "drinks",
    meal: "Minute Maid",
    price: 100,
    image_url: "",
  },

  {
    id: 24,
    category: "drinks",
    meal: "Soda",
    description: "",
    price: 100,
    image_url: "",
  },

  { id: 25, category: "drinks", meal: "Water", price: 50, image_url: "" },

  {
    id: 26,
    category: "shackSauces",
    meal: "Coleslaw",
    price: 50,
    image_url: "",
  },

  {
    id: 27,
    category: "shackSauces",
    meal: "Garlic Mayo",
    price: 50,
    image_url: "",
  },

  {
    id: 28,
    category: "shackSauces",
    meal: "white sauce",
    price: 50,
    image_url: "",
  },

  {
    id: 29,
    category: "shackSauces",
    meal: "Hot Chili",
    price: 50,
    image_url: "",
  },
];

export default data;
