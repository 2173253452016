import { createContext } from "react";

export const GlobalContext = createContext({
  globalState: {},
  updateGlobalState: () => {},
});

export const defaultGlobalState = {
  cartItems: {},
  cartTotal: {},
  itemsCount: 0,
  is_delivered: false,
};
