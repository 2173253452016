import data from "../data";

export const addToCart = (id, state) => {
  let {
    globalState,
    globalState: { cartItems, cartTotal, itemsCount },
    updateGlobalState,
  } = state;

  // check if item already in cart
  const itemExists = cartItems[id];

  if (itemExists) {
    itemExists.qty += 1;
    cartItems[id] = itemExists;

    // update cart total
    let total = findTotal(cartItems);
    cartTotal["total"] = total;
    itemsCount += 1;

    updateGlobalState({ ...globalState, cartItems, cartTotal, itemsCount });
  } else {
    const foundItem = data.find((item) => item.id === id);
    foundItem["qty"] = 1;
    cartItems[id] = foundItem;

    // update cart total,cart items total
    let total = findTotal(cartItems);
    cartTotal["total"] = total;
    itemsCount += 1;

    updateGlobalState({ ...globalState, cartItems, itemsCount });
  }
};

export const decrement = (id, state) => {
  let {
    globalState,
    globalState: { cartItems, cartTotal, itemsCount },
    updateGlobalState,
  } = state;

  const item = cartItems[id];

  if (item.qty === 1) {
    delete cartItems[id];

    // update cart total
    let total = findTotal(cartItems);
    cartTotal["total"] = total;
    itemsCount -= 1;

    updateGlobalState({ ...globalState, cartItems, cartTotal, itemsCount });
  } else {
    item.qty -= 1;
    cartItems[id] = item;

    // update cart total
    let total = findTotal(cartItems);
    cartTotal["total"] = total;
    itemsCount -= 1;

    updateGlobalState({ ...globalState, cartItems, cartTotal, itemsCount });
  }
};

const findTotal = (cartItems) => {
  let cartTotal = 0;
  Object.keys(cartItems).forEach((id) => {
    var price = cartItems[id].qty * cartItems[id].price;
    cartTotal += price;
  });

  return cartTotal;
};
