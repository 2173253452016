import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CalabashApp from "./Calabash-app";
import TheShackNairobi from "./calabash-app/theshacknairobi";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<CalabashApp />} />
        <Route path="/theshacknairobi" element={<TheShackNairobi />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
