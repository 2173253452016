export const finalUrlGenerator = (
  cartItems,
  is_delivered,
  total,
  inputValues
) => {
  const cartString = Object.keys(cartItems).map((id) => {
    var priceUrl = `%0A%E2%96%AA%20${cartItems[id].qty}%20x%20`;
    var itemName = cartItems[id].meal.replace(/ /g, "%20");
    return priceUrl + itemName + `%20%20KES%20` + cartItems[id].price;
  });
  const cartUrl = cartString.join();
  const instructionUrl = inputValues.message.replace(/ /g, "%20");
  const mpesaMessageUrl = inputValues.mpesaMessage.replace(/ /g, "%20");
  const addressUrl = inputValues.address1
    ? inputValues.address1.replace(/ /g, "%20")
    : "[Pick Up Order]".replace((/ /g, "%20"));
  const numberUrl = inputValues.phone.replace(/ /g, "");
  const mode = is_delivered ? "Delivery" : "Pickup";

  const finalUrl = `https://api.whatsapp.com/send?phone=254740729955&text=Hi,%20I%27d%20like%20to%20place%20an%20order%20%F0%9F%91%87%0A%0A%E2%9C%85%F0%9F%9B%92Order%20Details%0A${cartUrl}%0A%0AInstructions:${instructionUrl}%0A%0ATotal%20Price:%20KES%20${total}.00%0A%0A*${mode}%20Details%20%F0%9F%9B%92*%0A%0AName:%20${inputValues.name}%0AContact:%20%20${numberUrl}%0AAddress:%20${addressUrl}%0A%0A---%E2%80%90-----Mpesa%20Confirmation-----%0A${mpesaMessageUrl}`;

  return finalUrl;
};
