// set open or close depending on current time
export const openTime = () => {
  let hrs = new Date().getHours();
  let minutes = new Date().getMinutes() / 60;
  let operationTime = hrs + minutes;

  let operationStatus =
    operationTime >= 10 && operationTime < 21.5 ? "Open" : "Closed";

  return operationStatus;
};
