import React from "react";
import { useNavigate } from "react-router-dom";

function CalabashApp() {
  const navigate = useNavigate();

  const handleClick = (e) => {
    navigate("/theshacknairobi");
  };
  return (
    <div className="d-flex flex-column align-flex-items-center justify-content-center vh-100">
      <div className="m-auto text-center">
        <h1 className="mb-2">View Menus & Order via WhatsApp</h1>
        <button
          onClick={handleClick}
          className="btn btn-warning text-white mt-2"
        >
          1)The Shack Nairobi
        </button>
      </div>
    </div>
  );
}

export default CalabashApp;
