import data from "../data";

export const checkOfferDay = () => {
  let filteredBurgers = data.filter((meal) => meal.category === "burger");
  const isMonday = new Date().getDay() === 1; //checks if its on a monday

  const burgers = isMonday
    ? filteredBurgers.map((burger) => {
        const offerPrice = burger.price / 2;
        return { ...burger, price: offerPrice };
      })
    : filteredBurgers;

  return burgers;
};
