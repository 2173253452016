import React, { useState } from "react";
import orderStyles from "./styles/order.module.css";
import { ReactComponent as Whatsapp } from "../../assets/whatsapp.svg";
import { GlobalContext } from "../../helpers/context";
import { useContext } from "react";
import { finalUrlGenerator } from "../../helpers/whatsapp-url-generator";

function Order() {
  const [inputValues, setInputValues] = useState({
    name: "",
    address1: "",
    phone: "+254 ",
    message: "",
    mpesaMessage: "",
  });
  const deliverStyles = {
    color: "#28a745",
    textDecoration: "underline",
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  const {
    globalState,
    globalState: {
      cartItems,
      cartTotal: { total },
      is_delivered,
    },
    updateGlobalState,
  } = useContext(GlobalContext);

  const whatsappUrl = finalUrlGenerator(
    cartItems,
    total,
    is_delivered,
    inputValues
  );

  return (
    <div className={orderStyles.orderContainer}>
      <div className={orderStyles.orderButtons}>
        <button
          onClick={() => {
            updateGlobalState({ ...globalState, is_delivered: true });
          }}
          style={is_delivered ? deliverStyles : null}
        >
          Deliver
        </button>
        <button
          onClick={() => {
            updateGlobalState({ ...globalState, is_delivered: false });
          }}
          style={!is_delivered ? deliverStyles : null}
        >
          Pickup
        </button>
      </div>

      <form action="/post">
        <label htmlFor="name">Name</label>
        <br />
        <input
          value={inputValues.name}
          type="text"
          name="name"
          placeholder="Your name"
          onChange={handleChange}
        />
        <br />

        <label htmlFor="number">Whatsapp Number</label>
        <br />
        <input
          value={inputValues.phone}
          type="tel"
          name="phone"
          onChange={handleChange}
        />
        <br />
        {is_delivered && (
          <div>
            <label htmlFor="address1">
              Address (*help our rider locate you easily)
            </label>
            <br />
            <input
              value={inputValues.address1}
              type="text"
              name="address1"
              placeholder="Road, landmark, flat/hse no."
              onChange={handleChange}
            />
            <br />
          </div>
        )}
      </form>
      <p className={orderStyles.NB}>
        (Paying upfront helps us avoid food wastage on no-show orders)
      </p>
      <label htmlFor="mpesaMessage">
        Pay Kshs{total} to Mpesa Till{" "}
        <span className="text-primary">5377139 </span>
        LITTLE RED SHACK
      </label>
      <textarea
        name="mpesaMessage"
        id="mpesaMessage"
        rows="2"
        type="text"
        value={inputValues.mpesaMessage}
        placeholder="Copy/paste Mpesa msg here"
        onChange={handleChange}
      ></textarea>

      <label htmlFor="message">Instructions (optional)</label>
      <textarea
        name="message"
        type="text"
        rows="3"
        value={inputValues.message}
        placeholder="Add order notes,time for delivery/pickup etc "
        onChange={handleChange}
      ></textarea>

      <p className={orderStyles.NB}>
        We'll never spam you with unsolicited messages.
      </p>
      <a
        href={whatsappUrl}
        target="_blank"
        rel="noreferrer"
        className={orderStyles.orderButton}
      >
        <Whatsapp />
        <span className="ms-2">Send to WhatsApp</span>
      </a>
    </div>
  );
}

export default Order;
