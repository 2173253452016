import React, { useContext } from "react";
import cartStyles from "./styles/cart.module.css";
import { ReactComponent as Subtract } from "../../assets/dash-lg.svg";
import { ReactComponent as Add } from "../../assets/plus.svg";
import { GlobalContext } from "../../helpers/context";
import { addToCart, decrement } from "../../helpers/cart";

function Cart() {
  const {
    globalState,
    globalState: { cartItems, cartTotal, is_delivered },
    updateGlobalState,
  } = useContext(GlobalContext);

  const addToCartHandler = (id) => {
    addToCart(id, { globalState, updateGlobalState });
  };

  const removeFromCartHandler = (id) => {
    decrement(id, { globalState, updateGlobalState });
  };
  return (
    <>
      <section id="checkout" className={`${cartStyles.cartContainer}`}>
        {Object.keys(cartItems).length > 0 ? (
          <>
            <div className={cartStyles.title}>
              <span>Cart</span>
            </div>

            <div className={cartStyles.cartWrapper}>
              {Object.keys(cartItems).map((id, index) => (
                <div className={cartStyles.cartItem} key={index}>
                  <img
                    src={require("../../assets/shackLogo.png")}
                    alt="food-img"
                  />
                  <div className={cartStyles.mealWrapper}>
                    <p>{cartItems[id].meal}</p>
                    <span>{cartItems[id].qty}</span>
                  </div>
                  <div className={cartStyles.priceContainer}>
                    <Subtract
                      className={cartStyles.subtract}
                      onClick={(e) => removeFromCartHandler(cartItems[id].id)}
                    />
                    <span className={cartStyles.price}>
                      {cartItems[id].price}
                    </span>
                    <Add onClick={(e) => addToCartHandler(cartItems[id].id)} />
                  </div>
                </div>
              ))}
            </div>

            <div className={cartStyles.totalsWrapper}>
              <div className={cartStyles.total}>
                <p>SubTotal</p>
                <p>Ksh {cartTotal.total}</p>
              </div>
              {is_delivered && (
                <p className={cartStyles.deliveryNote}>
                  *Delivery Fee will be calculated after order is submitted*
                </p>
              )}
            </div>
          </>
        ) : (
          <p className="text-center p-2 bg-warning rounded text-white">
            cart is empty
          </p>
        )}
      </section>
    </>
  );
}

export default Cart;
