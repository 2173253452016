import { useEffect, useState } from "react";
import "../homePage.css";
import Cart from "../components/cart/Cart";
import Header from "../components/header/Header";
import Items from "../components/items/Items";
import Nav from "../components/nav/Nav";
import Order from "../components/order/Order";
import { defaultGlobalState, GlobalContext } from "../helpers/context";
import { ReactComponent as CartIcon } from "../assets/cart3.svg";

function TheShackNairobi() {
  const [globalState, setGlobalState] = useState(defaultGlobalState);

  const updateGlobalState = (obj) => setGlobalState({ ...globalState, ...obj });
  useEffect(() => {
    const sections = document.querySelectorAll("section");
    const links = document.querySelectorAll(".nav_link__JgVBD");

    const scrollHandler = () => {
      let current = "";
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        if (window.scrollY >= sectionTop) {
          current = section.getAttribute("id");
        }
      });

      links.forEach((link) => {
        link.classList.remove("active");
      });

      links.forEach((link) => {
        if (!current) return; //so not change if current link is empty
        link.style.color = "black"; //set all link to default black

        //checks if current is a substring of link href and changes style if yes
        if (link.attributes.href.value.includes(current)) {
          link.style.color = "#ae1f27";
        }
      });
    };

    window.addEventListener("scroll", scrollHandler);
    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  return (
    <GlobalContext.Provider
      value={{
        globalState,
        updateGlobalState,
      }}
    >
      <div className="app">
        <Header />
        <div className="container-fluid">
          <div className="row">
            <Nav />
            <Items />
            <div
              className="checkout  col-lg-3 col-md-4"
              style={{
                backgroundColor: "#f6f7f9",
              }}
            >
              <Cart />
              <Order />
            </div>
          </div>
        </div>
        <div className="cart-nav">
          <a href="#checkout">
            <CartIcon />
            <span className="checkout-counter">{globalState.itemsCount}</span>
          </a>
        </div>
      </div>
    </GlobalContext.Provider>
  );
}

export default TheShackNairobi;
